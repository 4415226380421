export default function wrapFolderHandlers({
  toast,
  folders,
  forceTableFetch,
  handleCreateFolder,
  handleRenameFolder,
  handleDeleteFolder,
  handleMoveFolder,
  handleMoveVideoToFolder,
}) {
  const wrap =
    ({ handler, onSuccess, onError }) =>
    async (...args) => {
      try {
        const result = await handler(...args);
        forceTableFetch();

        if (onSuccess) {
          onSuccess(result);
        }
      } catch (e) {
        if (onError) {
          onError(e);
        }

        if (e.response.status >= 500) {
          Error(e);
        }
      }
    };

  const onCreateFolder = wrap({
    handler: handleCreateFolder,
    onSuccess: ({ title }) => toast.success(`"${title}" has been created.`),
    onError: async () => toast.error('Error creating folder.'),
  });

  const onRenameFolder = wrap({
    handler: handleRenameFolder,

    onSuccess: async ({ oldTitle, newTitle }) => {
      toast.success(`"${oldTitle}" renamed to "${newTitle}".`);
    },

    onError: async () => toast.error('Error renaming folder.'),
  });

  const onDeleteFolder = wrap({
    handler: handleDeleteFolder,

    onSuccess: ({ oldTitle }) => toast.success(`"${oldTitle}" has been deleted.`),

    onError: async () => toast.error('Error deleting folder.'),
  });

  const onMoveFolder = wrap({
    handler: handleMoveFolder,

    onSuccess: async ({ sourceId, targetId }) => {
      const source = folders.find(({ id }) => id === sourceId);
      const target = folders.find(({ id }) => id === targetId);

      toast.success(`"${source.title}" has been moved to "${target.title}".`);
    },

    onError: async (e) => {
      const message =
        e.response.status === 403
          ? `You don’t have permission to move that folder.`
          : 'Error moving folder.';

      toast.error(message);
    },
  });

  const onMoveVideoToFolder = wrap({
    handler: handleMoveVideoToFolder,

    onSuccess: async ({ targetId }) => {
      const target = folders.find(({ id }) => id === targetId);
      toast.success(`Moved 1 item to "${target.title}".`);
    },

    onError: async () => toast.error('Error moving video to folder.'),
  });

  return {
    onCreateFolder,
    onRenameFolder,
    onDeleteFolder,
    onMoveFolder,
    onMoveVideoToFolder,
  };
}
