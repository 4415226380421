import { useEffect, useState } from 'react';

import {
  createPrivacyGroup,
  fetchAllPrivacyGroups,
  fetchVideoPermissions,
  updatePrivacyGroup,
  updatePrivacyGroupName,
  updateVideoPermissions,
} from 'api';

export default function useLinkPrivacyData({ videoId }) {
  const [permissions, setPermissions] = useState(null);
  const [privacyGroups, setPrivacyGroups] = useState(null);
  const [permissionsLoading, setPermissionsLoading] = useState(true);
  const [privacyGroupsLoading, setPrivacyGroupsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPermissions(await fetchVideoPermissions({ videoId }));
        setPermissionsLoading(false);
      } catch (e) {
        Error(e);
      }
    };

    fetchData();
  }, [videoId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPrivacyGroups(await fetchAllPrivacyGroups());
        setPrivacyGroupsLoading(false);
      } catch (e) {
        Error(e);
      }
    };

    fetchData();
  }, []);

  const handlePermissionsChange = async (nextPermissions) => {
    const prevPremissions = permissions;
    setPermissions(nextPermissions);

    try {
      await updateVideoPermissions({
        videoId,
        update: {
          permissions: nextPermissions,
        },
      });

      const hasEmailsOrDomains =
        nextPermissions.find((p) => ['email', 'domain'].includes(p.source)) !== undefined;

      // Refresh the permissions from the server so we can
      // learn what IDs were assigned to each new email/domain
      if (hasEmailsOrDomains) {
        setPermissions(await fetchVideoPermissions({ videoId }));
      }
    } catch (e) {
      Error(e);
      setPermissions(prevPremissions);
    }
  };

  const handlePrivacyGroupCreated = async ({ name, permissions }) => {
    try {
      const { id } = await createPrivacyGroup({ name });
      await updatePrivacyGroup({ id, permissions });

      const newGroup = { id, teamName: name };
      const nextPermissions = [{ source: 'self' }, { source: 'Privacy Team', value: { team: id } }];

      setPrivacyGroups([...privacyGroups, newGroup]);
      handlePermissionsChange(nextPermissions);
    } catch (e) {
      Error(e);
    }
  };

  const handlePrivacyGroupUpdated = async ({ id, name, permissions }) => {
    try {
      await Promise.all([
        name && updatePrivacyGroupName({ id, name }),
        permissions && updatePrivacyGroup({ id, permissions }),
      ]);

      if (name) {
        setPrivacyGroups(
          privacyGroups.map((group) => ({
            ...group,
            teamName: group.id === id ? name : group.teamName,
          })),
        );
      }
    } catch (e) {
      Error(e);
    }
  };

  return {
    permissions,
    privacyGroups,
    permissionsLoading,
    privacyGroupsLoading,
    handlePermissionsChange,
    handlePrivacyGroupCreated,
    handlePrivacyGroupUpdated,
  };
}
