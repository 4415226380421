import * as React from 'react'
import cx from 'classnames'
import { Icon } from '@driftt/tide-icons'

function ToastItem({ onCloseClick, message, type, dismissed }) {
  return (
    <div
      className={cx('toast-item', {
        'toast-item--info': type === 'info',
        'toast-item--success': type === 'success',
        'toast-item--error': type === 'error',
        'toast-item--dismissed': dismissed,
      })}
    >
      {message}
      <button
        className="toast-item__close"
        onClick={onCloseClick}
        tabIndex={-1}
      >
        <Icon width={10} height={10} name="close" />
      </button>
    </div>
  )
}

export default ToastItem
