import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Footer, Modal } from '@driftt/tide-core';

import ModalPortal from 'components/modalPortal';

interface ConfirmationModalProps {
  title?: string;
  size?: string;
  confirmButtonType?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  [x: string]: any; // otherProps
}

const initialModalProps = {
  open: false,
  onClose: () => {},
  onConfirmClick: () => {},
};

// Returns an array with two elements. The first is an async function you can
// call to show a confirmation modal (in a click handler, for instance). That
// function return a Promise of a boolean value that reflects whether the user
// confirmed or not. The second is a custom Modal component you can put
// anywhere in your render tree. It takes the same props as the Tide Modal but
// you don't need an `open` or `onClose` (it will show/hide itself
// automatically). It also adds buttons automatically that you can optionally
// customize with `confirmButtonType`, `confirmButtonText` and
// `cancelButtonText`.
export default function useConfirmation(): [
  () => Promise<boolean>,
  React.FC<ConfirmationModalProps>,
] {
  const [modalProps, setModalProps] = useState(initialModalProps);

  const getConfirmation = useCallback<() => Promise<boolean>>(
    () =>
      new Promise((resolve) => {
        setModalProps({
          open: true,
          onClose: () => {
            resolve(false);
            setModalProps(initialModalProps);
          },
          onConfirmClick: () => {
            resolve(true);
            setModalProps(initialModalProps);
          },
        });
      }),
    [],
  );

  const previouslyOpen = useRef(false);

  const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    title = 'Are you sure?',
    size = 'small',
    confirmButtonType = 'secondary',
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    children,
    ...otherProps
  }) => {
    // This is needed for the fade-in animation.
    const [open, setOpen] = useState(previouslyOpen.current);

    useEffect(() => {
      if (modalProps.open) {
        setOpen(true);
        previouslyOpen.current = true;
      } else {
        setOpen(false);
        previouslyOpen.current = false;
      }
    }, []);

    return (
      <ModalPortal>
        <Modal title={title} size={size} open={open} onClose={modalProps.onClose} {...otherProps}>
          {children}
          <Footer>
            <Button type={confirmButtonType} onClick={modalProps.onConfirmClick}>
              {confirmButtonText}
            </Button>
            <Button type="tertiary" onClick={modalProps.onClose}>
              {cancelButtonText}
            </Button>
          </Footer>
        </Modal>
      </ModalPortal>
    );
  };

  return [getConfirmation, ConfirmationModal];
}
