import React from 'react';

const VideoShareContext = React.createContext({
  hasSubmittedQualityFeedback: null,
  setHasSubmittedQualityFeedback: () => {},
  showOnboardingState: null,
  setShowOnboardingState: () => {},
});

export default VideoShareContext;
