import React from 'react'

import { PrivacyStatus } from 'types/interfaces'

const FolderContext = React.createContext({
  folder: null,
  folders: [{
    id: '',
    title: '',
    privacyStatus: PrivacyStatus.Individual,
  }],
  onCreateFolder: () => null,
  onRenameFolder: () => null,
  onDeleteFolder: () => null,
  onMoveFolder: () => null,
  onMoveVideoToFolder: (data) => null,
  refreshFolderSelection: () => null,
})

export default FolderContext
