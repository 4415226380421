import * as React from 'react'
import ToastItem from './ToastItem'

import './ToastContainer.css'

const { useCallback, useEffect, useState, useRef } = React

export default function useToastNotifications() {
  const [itemId, setItemId] = useState(1)
  const [message, setMessage] = useState('')
  const [type, setType] = useState('success')
  const [status, setStatus] = useState('hidden')
  let timeoutId: any = useRef(null)

  const dismiss = useCallback(() => {
    setStatus('dismissed')
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => setStatus('hidden'), 250)
  }, [timeoutId, setStatus])

  const makeToastFn = useCallback(
    (type) => (message) => {
      setItemId((id) => id + 1)
      setMessage(message)
      setType(type)
      setStatus('active')

      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }

      timeoutId.current = setTimeout(dismiss, 5000)
    },
    [setItemId, setMessage, setType, setStatus, dismiss]
  )

  useEffect(() => () => clearTimeout(timeoutId.current), [timeoutId])

  const toast = makeToastFn('info')
  // @ts-ignore
  toast.info = makeToastFn('info')
  // @ts-ignore
  toast.success = makeToastFn('success')
  // @ts-ignore
  toast.error = makeToastFn('error')

  const ToastContainer = useCallback(
    ({ className }) => {
      return (
        <div className={className || 'toast-container'}>
          {status !== 'hidden' && (
            <ToastItem
              key={itemId}
              message={message}
              type={type}
              onCloseClick={dismiss}
              dismissed={status === 'dismissed'}
            />
          )}
        </div>
      )
    },
    [message, status, type, itemId, dismiss]
  )

  return {
    toast,
    ToastContainer,
  }
}
