import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'

import { UserCustomizations } from 'types/interfaces'

import { createOrUpdateUserCustomization, fetchUserCustomization } from 'api'

interface UserCustomizationsContextProps {
  userCustomization: UserCustomizations
  userCustomizationLoading: boolean
  handleUserCustomizationChange: (value: UserCustomizations) => void
}

export const UserCustomizationsContext = React.createContext<UserCustomizationsContextProps>({
  userCustomization: {},
  userCustomizationLoading: false,
  handleUserCustomizationChange: () => {},
})

const UserCustomizationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userCustomization, setUserCustomization] = useState<UserCustomizations>({})
  const [userCustomizationLoading, setUserCustomizationLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchUserCustomization()
        setUserCustomization(result)
        setUserCustomizationLoading(false)
      } catch (e) {
        throw Error((e as any).message) 
      }
    }

    fetchData()
  }, [])

  const handleUserCustomizationChange = useCallback(
    async (newValue) => {
      const rollback = { ...userCustomization }

      setUserCustomization(newValue)

      try {
        await createOrUpdateUserCustomization(newValue)
      } catch (e) {
        Error((e as any).message) 
        setUserCustomization(rollback)
      }
    },
    [userCustomization]
  )

  return (
    <UserCustomizationsContext.Provider
      value={{ userCustomization, userCustomizationLoading, handleUserCustomizationChange }}>
      {children}
    </UserCustomizationsContext.Provider>
  )
}

export default UserCustomizationsProvider
