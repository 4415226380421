import React from 'react';

import { segmentTrack } from 'utils/metrics';

const MetricsContext = React.createContext({
  addBreadcrumb: () => {},
  trackCTA: () => {},
  trackVideoQuality: () => {},
});

export const makeTrackCTA = (videoId, environment) => () => {
  if (!['qa', 'prod'].includes(environment)) {
    return;
  }

  segmentTrack('CTA Clicked', {
    product: 'video',
    location: 'Top Right',
    color: 'Blue',
    text: 'Copy link + GIF',
    CTA: 'Sharing',
    category: 'Edit Page',
    type: 'Link',
    videoId,
    isReactPage: true,
  });
};

export const makeTrackVideoQuality = (videoId, environment) => (rating) => {
  if (!['MASTER', 'PRODUCTION'].includes(environment)) {
    return;
  }

  segmentTrack('Video Quality Track', {
    rating,
    videoid: videoId,
  });
};

export default MetricsContext;
