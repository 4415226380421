import * as React from 'react'
import { useContext, useEffect, useState } from 'react'

import * as qs from 'query-string'
import { Folder, PlaceholderFolder } from 'types/interfaces'

import FolderContext from 'context/FolderContext'
import { UserContext } from 'context/UserContext'
import useFolderData from 'hooks/useFolderData'
import useToastNotifications from 'hooks/useToastNotifications'
import wrapFolderHandlers from 'hooks/wrapFolderHandlers'
import {
  placeholderHowToVideosFolder,
  placeholderRootPersonalLibraryFolder,
  placeholderRootTeamLibraryFolder,
} from 'utils/folderUtils'

const FolderProvider = ({ children }: any) => {
  const { toast, ToastContainer } = useToastNotifications()
  const [folder, setFolder] = useState<Folder | PlaceholderFolder | null>(null)

  const { userHasFeature } = useContext(UserContext)
  const hasTeamLibrary = userHasFeature('HAS_TEAM_LIBRARY')

  const {
    folders,
    handleCreateFolder,
    handleRenameFolder,
    handleDeleteFolder,
    handleMoveFolder,
    handleMoveVideoToFolder,
    handleFolderFetched,
  } = useFolderData()

  const { onCreateFolder, onRenameFolder, onDeleteFolder, onMoveFolder, onMoveVideoToFolder } =
    wrapFolderHandlers({
      // Wrap all the handlers that edit folders such that they also force a table
      // update.
      handleCreateFolder,
      handleRenameFolder,
      handleDeleteFolder,
      handleMoveFolder,
      handleMoveVideoToFolder,

      // Provide access to the `toast` helper and `folders` so these handlers can
      // send notifications.
      toast,
      folders,

      // The table watches `folder` for changes so merely cloning it will trigger
      // a fetch.
      forceTableFetch: () => setFolder((folder) => (folder ? { ...folder } : null)),
    })

  function refreshFolderSelection() {
    const params = qs.parse(window.location.search)
    const folderId = parseInt(params.folder_id)
    const isTeamLibrary = window.location.pathname.indexOf('/video/team/') > -1
    const isHowToLibrary = window.location.pathname.indexOf('/video/how-to/') > -1

    if (params.source_type) {
      // Allow fetching Zoom recordings (source_type == 3) which do not have
      // a parent folder.
      setFolder(null)
    } else {
      const folder = folders.find((f) => f.id === folderId)
      if (folder) {
        setFolder(folder)
      } else {
        // If we haven't fetched the list of folders yet [or if the user has
        // yet to make a request to /apis/v1/(my|team)-videos to create them]
        // we use one of these temporary "fake folders." They allow the nav
        // to, at mimimum, represent that two top-level folders that should
        // logically exist.
        const tempFolder =
          hasTeamLibrary && isTeamLibrary
            ? placeholderRootTeamLibraryFolder
            : isHowToLibrary
            ? placeholderHowToVideosFolder
            : placeholderRootPersonalLibraryFolder
        setFolder(tempFolder)
      }
    }
  }
  useEffect(() => {
    refreshFolderSelection()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FolderContext.Provider
      value={{
        folder,
        folders,
        onCreateFolder,
        onRenameFolder,
        onDeleteFolder,
        onMoveFolder,
        onMoveVideoToFolder,
        refreshFolderSelection,
        handleFolderFetched,
      } as any}>
      <ToastContainer className="video-library__toast-container" />
      {children}
    </FolderContext.Provider>
  )
}

export default FolderProvider
