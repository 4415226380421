import { useCallback, useEffect, useRef, useState } from 'react';

import {
  createGifFromVideo,
  fetchPlayerOptions,
  fetchVideoData,
  updatePlayerOptions,
  updateVideoData,
  updateVideoSources,
  updateVideoVisibility,
} from 'api';

export default function useVideoData({ videoId }) {
  const [videoDataLoading, setVideoDataLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [playerOptionsLoading, setPlayerOptionsLoading] = useState(true);
  const [videoData, setVideoData] = useState(null);
  const [optionsId, setOptionsId] = useState(null);
  const [playerOptions, setPlayerOptions] = useState(null);
  const playerRetries = useRef(0);

  const fetchData = useCallback(async () => {
    fetchData.timeoutIds = fetchData.timeoutIds || [];

    try {
      const result = await fetchVideoData(videoId).catch(() => {
        setIsAuthorized(false);
      });
      setVideoData(result);
      setVideoDataLoading(false);

      if (result.videoSources.length === 0) {
        const timeoutId = setTimeout(fetchData, 3000);
        fetchData.timeoutIds.push(timeoutId);
      }
    } catch (e) {
      Error(e);
    }
  }, [videoId]);

  const fetchPlayerOptionsData = useCallback(async () => {
    try {
      const playerOptions = await fetchPlayerOptions(videoId);
      setPlayerOptions(playerOptions.data);
      setOptionsId(playerOptions.id);
    } catch (e) {
      Error(e);
    } finally {
      setPlayerOptionsLoading(false);
    }
  }, [videoId]);

  useEffect(() => {
    fetchData();
    fetchPlayerOptionsData();
    // Clean up any timeouts in progress.
    return () => fetchData.timeoutIds.forEach(clearTimeout);
  }, [fetchData, fetchPlayerOptionsData, videoId]);

  const handlePlayerCanPlay = useCallback(() => {
    playerRetries.current = 0;
  }, []);

  const handlePlayerError = useCallback(
    async ({ error }) => {
      // see https://docs.videojs.com/mediaerror#.errorTypes
      if ([2, 4].includes(error.code) && playerRetries.current < 3) {
        playerRetries.current = playerRetries.current + 1;
        setVideoData((videoData) => ({ ...videoData, videoSources: [] }));
        fetchData();
      }
    },
    [fetchData],
  );

  const handleAudioIncludedChange = useCallback(
    async (nextValue) => {
      const update = { isAudioIncluded: nextValue };
      const rollback = { isAudioIncluded: !nextValue };

      setVideoData((videoData) => ({ ...videoData, ...update }));

      try {
        await updateVideoData({ videoId, update });
      } catch (e) {
        Error(e);
        setVideoData((videoData) => ({ ...videoData, ...rollback }));
      }
    },
    [videoId],
  );

  const handlePlayerOptionChange = useCallback(
    async (newOptions) => {
      const rollback = { ...playerOptions };

      setPlayerOptions((playerOptions) => ({ ...playerOptions, ...newOptions }));

      try {
        await updatePlayerOptions({ optionsId, options: newOptions });
      } catch (e) {
        Error(e);
        setPlayerOptions((playerOptions) => ({ ...playerOptions, ...rollback }));
      }
    },
    [optionsId, playerOptions],
  );

  const handleVideoDefaultThumbnailChange = useCallback(
    async (selectedThumbnail) => {
      const rollback = { ...videoData };
      const update = { thumbnail: selectedThumbnail };
      setVideoData((videoData) => ({ ...videoData, ...update }));

      try {
        await updateVideoData({ videoId, update });
        fetchData();
      } catch (e) {
        Error(e);
        setVideoData(() => ({ ...rollback }));
      }
    },
    [videoId, videoData, fetchData],
  );

  const handleVideoVisibilityChange = useCallback(
    async (videoVisibility) => {
      const rollback = { ...videoData };
      setVideoData((videoData) => ({ ...videoData, videoVisibility }));

      try {
        await updateVideoVisibility({ videoId, videoVisibility });
      } catch (e) {
        Error(e);
        setVideoData(() => ({ ...rollback }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [videoId],
  );

  const handleVideoTrimmed = useCallback(
    async (nextValue) => {
      const update = {
        startTime: nextValue.startTime,
        endTime: nextValue.endTime,
      };

      const rollback = {
        starTime: videoData.startTime,
        endTime: videoData.endTime,
      };

      setVideoData((videoData) => ({ ...videoData, ...update }));

      try {
        await updateVideoData({ videoId, update });
        fetchData();
      } catch (e) {
        Error(e);
        setVideoData((videoData) => ({ ...videoData, ...rollback }));
      }
    },
    [videoId, videoData, fetchData],
  );

  const handleSaveCreatedGif = useCallback(
    async ({ gifCropHeight, gifCropWidth, gifCropX, gifCropY, gifEndTime, gifStartTime }) => {
      try {
        const result = await createGifFromVideo({
          gifCropHeight,
          gifCropWidth,
          gifCropX,
          gifCropY,
          gifEndTime,
          gifStartTime,
          videoId,
          streamType: videoData.videoSources[0].stream,
          title: videoData.title,
          titleEdited: false,
        });

        return result;
      } catch (e) {
        Error(e);
      }
    },
    [videoId, videoData],
  );

  const handleSaveUploadedThumbnail = useCallback(
    async (thumbnailUrl) => {
      const videoSourceId = videoData.videoSources[0].id;
      const thumbnailFileName = thumbnailUrl.split('/').pop();
      let update = { custom_thumbnail_file: thumbnailFileName };

      try {
        await updateVideoSources({ videoSourceId, update });

        setVideoData((videoData) => ({
          ...videoData,
          thumbnailUrl,
        }));

        // Refetch the video data to get updated thumbnail variants.
        fetchData();
      } catch (e) {
        Error(e);
      }
    },
    [videoData, fetchData],
  );

  const handleDeleteUploadedThumbnail = useCallback(
    async (thumbnailUrl) => {
      const videoSourceId = videoData.videoSources[0].id;
      let update = { custom_thumbnail_file: '' };

      try {
        await updateVideoSources({ videoSourceId, update });

        setVideoData((videoData) => ({
          ...videoData,
          thumbnailUrl,
        }));

        // Refetch the video data to get updated thumbnail variants.
        fetchData();
      } catch (e) {
        Error(e);
      }
    },
    [videoData, fetchData],
  );

  return {
    videoData,
    isAuthorized,
    playerOptions,
    playerOptionsLoading,
    videoDataLoading,
    handlePlayerCanPlay,
    handlePlayerError,
    handleAudioIncludedChange,
    handlePlayerOptionChange,
    handleVideoDefaultThumbnailChange,
    handleVideoVisibilityChange,
    handleVideoTrimmed,
    handleSaveCreatedGif,
    handleSaveUploadedThumbnail,
    handleDeleteUploadedThumbnail,
  };
}
