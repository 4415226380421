import { useCallback, useContext, useEffect, useState } from 'react';

import { createFolder, deleteFolder, fetchFolders, moveVideoToFolder,updateFolder } from 'api';
import { UserContext } from 'context/UserContext';
import {
  findFolderById,
  isRootPersonalLibraryFolder,
  isRootTeamLibraryFolder,
  placeholderRootPersonalLibraryFolder,
  placeholderRootTeamLibraryFolder,
} from 'utils/folderUtils';

export default function useFolderData() {
  const { userHasFeature, appLoading, user } = useContext(UserContext);
  const hasTeamLibrary = userHasFeature('HAS_TEAM_LIBRARY');

  const defaultFolders = [];

  // This folder will be created the first time it's read. Therefore we know
  // a priori that such a folder is fetchable but not what its id will be.
  defaultFolders.push(placeholderRootPersonalLibraryFolder);

  if (hasTeamLibrary) {
    defaultFolders.push(placeholderRootTeamLibraryFolder);
  }

  const [folders, setFolders] = useState(defaultFolders);

  const fetchList = useCallback(async () => {
    try {
      const data = await fetchFolders();
      const hasTeamLibrary = userHasFeature('HAS_TEAM_LIBRARY');

      // If one of the root folder has not been created yet the response
      // will not contain it (they can only be created by a call to
      // /folders/my-videos/ or /folders/team-videos/). In that case we'll
      // add in one or both of the placeholder folder objects.
      let folders = [...data];

      if (data.every((f) => !isRootPersonalLibraryFolder(f))) {
        folders.push(placeholderRootPersonalLibraryFolder);
      } else {
        // HACK: Replace the root folder's title as it appears in the database
        // (which may be outdated) with what we're calling it these days.
        folders = folders.map((folder) => ({
          ...folder,
          title: isRootPersonalLibraryFolder(folder)
            ? placeholderRootPersonalLibraryFolder.title
            : folder.title,
        }));
      }

      if (hasTeamLibrary) {
        if (data.every((f) => !isRootTeamLibraryFolder(f))) {
          folders.push(placeholderRootTeamLibraryFolder);
        } else {
          // HACK: Replace the root folder's title as it appears in the database
          // (which may be outdated) with what we're calling it these days.
          folders = folders.map((folder) => ({
            ...folder,
            title: isRootTeamLibraryFolder(folder)
              ? placeholderRootTeamLibraryFolder.title
              : folder.title,
          }));
        }
      }

      setFolders(folders);
    } catch (e) {
      Error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userHasFeature]);

  useEffect(() => {
    if (!appLoading && user) {
      fetchList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLoading, user]);

  const handleCreateFolder = async ({ title, parentFolder, privacyStatus }) => {
    const folder = await createFolder({ title, parentFolder, privacyStatus });
    setFolders((prevFolders) => [...prevFolders, folder]);
    return folder;
  };

  const handleRenameFolder = useCallback(
    async ({ id, title }) => {
      const prevFolder = findFolderById(id, folders);

      const nextFolder = await updateFolder({
        id,
        update: {
          title,
          datetimeUpdated: prevFolder?.datetimeUpdated,
        },
      });

      setFolders((folders) => {
        return folders.map((folder) => ({
          ...folder,
          title: folder.id === nextFolder.id ? nextFolder.title : folder.title,
        }));
      });

      return {
        oldTitle: prevFolder?.title,
        newTitle: title,
      };
    },
    [folders],
  );

  const handleDeleteFolder = async ({ id }) => {
    await deleteFolder({ id });
    let oldTitle = '';

    setFolders((folders) => {
      oldTitle = folders.find((folder) => folder.id === id).title;
      return folders.filter((folder) => folder.id !== id);
    });

    return { oldTitle };
  };

  const handleMoveFolder = useCallback(
    async ({ id, parentFolder, privacyStatus, title }) => {
      const { datetimeUpdated } = findFolderById(id, folders);

      const nextFolder = await updateFolder({
        id,
        update: { parentFolder, privacyStatus, title, datetimeUpdated },
      });

      setFolders((folders) =>
        folders.map((folder) => ({
          ...folder,
          parentFolder: folder.id === nextFolder.id ? nextFolder.parentFolder : folder.parentFolder,
          privacyStatus:
            folder.id === nextFolder.id ? nextFolder.privacyStatus : folder.privacyStatus,
        })),
      );

      return {
        sourceId: id,
        targetId: parentFolder,
      };
    },
    [folders],
  );

  const handleMoveVideoToFolder = async ({ videoId, folderId }) => {
    await moveVideoToFolder({ videoId, folderId });

    return {
      targetId: folderId,
    };
  };

  // After a folder has been fetched, update our list of known folders with
  // information we learned about it. Specifically, we may have just learned
  // about the `id` of a top-level folder that was previously unassigned.
  const handleFolderFetched = useCallback((updatedFolder) => {
    setFolders((folders) => {
      if (isRootPersonalLibraryFolder(updatedFolder)) {
        const folderToPatch = folders.find(isRootPersonalLibraryFolder);
        const idIsUnknown = folderToPatch && folderToPatch.id === undefined;
        if (idIsUnknown) {
          return folders.map((folder) => ({
            ...folder,
            id: isRootPersonalLibraryFolder(folder) ? updatedFolder.id : folder.id,
          }));
        }
      }
      if (isRootTeamLibraryFolder(updatedFolder)) {
        const folderToPatch = folders.find(isRootTeamLibraryFolder);
        const idIsUnknown = folderToPatch && folderToPatch.id === undefined;
        if (idIsUnknown) {
          return folders.map((folder) => ({
            ...folder,
            id: isRootTeamLibraryFolder(folder) ? updatedFolder.id : folder.id,
          }));
        }
      }
      return folders;
    });
  }, []);

  return {
    folders,
    handleCreateFolder,
    handleRenameFolder,
    handleDeleteFolder,
    handleMoveFolder,
    handleMoveVideoToFolder,
    handleFolderFetched,
  };
}
