import * as React from 'react';
import { useEffect,useState } from 'react';

import { DriftUser, User } from 'types/interfaces';

import { access } from '@driftt/traffic-control';
import { checkForGlobalSession } from '@driftt/web-auth';

import { fetchDriftUser,fetchUserDetails } from 'api';

const EXTENSION_AUTH_CUTOVER_NAME = 'HAS_NEW_EXTENSION_AUTH';

interface UserContextProps {
  user: User | undefined;
  tokenLoaded: boolean;
  userLoading: boolean;
  appLoading: boolean;
  userHasFeature: (featureName: string) => boolean;
  isFreeVideoUser: () => boolean;
  canInviteTeammates: () => boolean;
  hasChatSeat: () => boolean;
  hasUnifiedSeatExperience: boolean;
  createUrlPath: (pathname: string) => string;
}

interface UserProviderProps {
  maybeVideoUser?: User | undefined;
  maybeDriftUser?: DriftUser | undefined;
  children: React.ReactNode;
}

export const UserContext = React.createContext<UserContextProps>({
  user: undefined,
  tokenLoaded: false,
  userLoading: true,
  appLoading: true,
  userHasFeature: () => false,
  isFreeVideoUser: () => true,
  canInviteTeammates: () => false,
  hasChatSeat: () => false,
  hasUnifiedSeatExperience: false,
  createUrlPath: () => '/',
});

const UserProvider = ({ children, maybeVideoUser, maybeDriftUser }: UserProviderProps) => {
  const [user, setUser] = useState<User | undefined>(maybeVideoUser);
  const [driftUser, setDriftUser] = useState<DriftUser | undefined>(maybeDriftUser);
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [appLoading, setAppLoading] = useState(true);

  // sync the tokens to ensure we have a cookie
  useEffect(() => {
    checkForGlobalSession().then(() => setTokenLoaded(true));
  }, []);

  // fetch both the video and drift users
  useEffect(() => {
    if (tokenLoaded) {
      const fetchUserData = () => {
        return fetchUserDetails()
          .then((result) => {
            result.avatarUrl = result.image;
            result.name = [result.firstName, result.lastName].filter(Boolean).join(' ');

            setUser(result);
          })
          .catch((e) => Error(e));
      };

      const fetchDriftUserData = () => {
        return fetchDriftUser()
          .then(({ data }) => setDriftUser(data))
          .catch((e) => Error(e));
      };

      Promise.all([!user && fetchUserData(), !driftUser && fetchDriftUserData()]).then(() => {
        setUserLoading(false);
      });
    }
  }, [tokenLoaded, user, driftUser]);

  // validate the user's access to the current page, redirect otherwise
  useEffect(() => {
    if (!userLoading) {
      setAppLoading(false);
    }
  }, [userLoading]);

  const userHasFeature = (featureName: string) => {
    return Boolean(user?.subscription.userFeatures.includes(featureName));
  };

  // Setting the extension auth cutover value
  useEffect(() => {
    if (!userLoading) {
      window.localStorage.setItem(
        EXTENSION_AUTH_CUTOVER_NAME,
        String(userHasFeature(EXTENSION_AUTH_CUTOVER_NAME)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading]);

  const isFreeVideoUser = () => {
    return Boolean(
      user?.subscription?.userSubscriptions &&
        Object.keys(user.subscription.userSubscriptions).indexOf('HYFY_FREE') > -1,
    );
  };

  const canInviteTeammates = () => {
    return Boolean(user?.driftUserData?.canInviteTeammates);
  };

  const hasChatSeat = () => {
    return Boolean(user?.driftUserData?.hasChatSeat);
  };

  const hasUnifiedSeatExperience = !access.isVideoOnlyUser();

  const createUrlPath = (pathname: string) => {
    return pathname;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        tokenLoaded,
        userLoading,
        appLoading,
        userHasFeature,
        isFreeVideoUser,
        canInviteTeammates,
        hasChatSeat,
        hasUnifiedSeatExperience,
        createUrlPath,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
