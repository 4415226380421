import React from 'react'
import ReactDOM from 'react-dom'

import { LoadStyles } from '@driftt/tide-core'
import ConnectedApp from '@driftt/traffic-control'

import { CLIENT_ID } from 'utils/constants'

import App from './App'

LoadStyles()

ReactDOM.render(<ConnectedApp render={() => <App />} authClientId={CLIENT_ID} vNext/>, document.getElementById('root'))
