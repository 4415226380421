import { useEffect, useRef, useState } from 'react';

import { fetchVideoViews } from 'api';
import { pageSize } from 'utils/constants';

export default function useViewsData({ videoId }) {
  const busy = useRef(false);
  const cache = useRef([]);
  const nextCursor = useRef(null);

  const [page, setPage] = useState(0);
  const [results, setResults] = useState(null);
  const [totalViews, setTotalViews] = useState(null);
  const [viewsLoading, setViewsLoading] = useState(true);

  const fetchData = async () => {
    const cached = cache.current[page];

    if (cached) {
      return setResults(cached);
    }

    try {
      busy.current = true;

      const { count, next, results } = await fetchVideoViews({
        cursor: nextCursor.current,
        pageSize,
        videoId,
      });

      cache.current.push(results);

      // Pull the cursor out of the `next` property (which is formatted
      // as a fully-qualified URL) so that we can pass to the api again.
      nextCursor.current = next && decodeURIComponent(next.match(/\bcursor=([^&$]+)/)[1]);

      setResults(results);
      setTotalViews(count);
      setViewsLoading(false);
    } catch (e) {
      Error(e);
    } finally {
      busy.current = false;
    }
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, videoId]);

  const getNextPage = () => {
    if (!busy.current) {
      setPage(page + 1);
    }
  };

  const getPrevPage = () => {
    if (!busy.current) {
      setPage(page - 1);
    }
  };

  return {
    getNextPage,
    getPrevPage,
    page,
    results,
    totalViews,
    viewsLoading,
  };
}
