import { useCallback, useEffect, useState } from 'react';

import { createOrUpdateUserCustomization,fetchUserCustomization } from 'api';

export default function useUserCustomization() {
  const [userCustomizationLoading, setUserCustomizationLoading] = useState(true);
  const [userCustomization, setUserCustomization] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchUserCustomization();
        setUserCustomization(result);
        setUserCustomizationLoading(false);
      } catch (e) {
        Error(e);
      }
    };

    fetchData();
  }, []);

  const updateUserCustomization = useCallback(async (customization) => {
    try {
      const result = await createOrUpdateUserCustomization(customization);
      setUserCustomization(result);
    } catch (e) {
      Error(e);
    }
  }, []);

  return {
    userCustomization,
    userCustomizationLoading,
    updateUserCustomization,
  };
}
